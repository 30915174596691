<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" md="6">
        <listitems
          :list="machine_list"
          :qDelete="Qdelete"
          :title="'Liste des Machine '"
          :headers="headers"
          :filename="'Machine'"
          :sheetname="'Machine'"
          :showedit="false"
          :key="klist"
          :del_disable="machine.nbin > 0"
          @open="MachineOpen"
          @rowselect="MachineSelect"
          :add="true"
          :del="true"
          :laoding="loading"
        >
        </listitems>
      </v-col>
    </v-row>
    <machineform
      :item="machine"
      :items="machine_list"
      :structures="structures"
      :showForm="!isMachineClose"
      :key="mf"
      @close="closeMachineForm"
      :readonly="false"
    >
    </machineform>
    <template>
      <confirmdialog ref="confirm" />
    </template>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
const listitems = () => import("../components/ListItems.vue");
const confirmdialog = () => import("../components/ConfirmDialog.vue");

import DELETE_MACHINE from "../graphql/Production/DELETE_MACHINE.gql";
import MACHINES from "../graphql/Production/MACHINES.gql";

export default {
  components: {
    listitems,
    confirmdialog,
    machineform: () => import("../components/MachineForm.vue"),
  },

  data: () => ({
    loading: false,
    editer: false,
    isMachineClose: true,
    klist: 400,
    kpin: 500,
    kpout: 600,
    editeditem: {},
    title: "",
    mf: 300,
    machine: {},
    product: {},
    gamme: {},
    headers: [
      {
        text: "No.",
        value: "no",
        selected: true,
      },
      {
        text: "Code",
        value: "code",
        selected: true,
      },
      {
        text: "Date creation",
        value: "created_at",
        selected: true,
      },
      {
        text: "Reference",
        value: "ref",
        selected: true,
      },
      {
        text: "Machine",
        value: "label",
        slot: "href",
        selected: true,
      },
      {
        text: "Atelier",
        value: "atelier",
        selected: true,
      },
      {
        text: "Date d'aquisition",
        value: "date_acc",
        selected: false,
      },
      {
        text: "Valeur d'aquisition",
        value: "valeur_acc",
        selected: false,
      },

      {
        text: "Vitesse Max",
        value: "vitessemax",
        selected: false,
      },

      {
        text: "Description",
        value: "description",
        selected: false,
      },
    ],

    machine_list: [],
    structures: [],
    units: [],
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
  }),
  watch: {},
  computed: {
    ateliers() {
      return this.structures.filter(
        (elm) => elm.type_id == 3 && elm.ChildCount == 0
      );
    },

    Qdelete() {
      return DELETE_MACHINE;
    },
  },
  created() {},

  async mounted() {
    this.get_data();
    this.headers[5].selected = this.ateliers.length > 1;
  },
  methods: {
    async requette(query, v) {
      let r;

      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async get_data() {
      this.loading = true;
      let r = await this.requette(MACHINES);
      if (r) {
        this.machine_list = r.machines;
        this.units = r.units;
        this.structures = r.structures;
        this.klist++;
      }
      this.loading = false;
    },
    closeMachineForm() {
      this.isMachineClose = true;
    },
    MachineSelect(item) {
      this.machine = item;
      this.kpin++;
    },

    MachineOpen(item) {
      this.machine = item;
      this.isMachineClose = false;
      this.mf++;
    },
  },
};
</script>

<style></style>
